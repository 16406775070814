<template>
  <div>
    <div class="white box">
      <!-- bar -->
      <v-card flat class="pa-4 bar" v-if="$store.getters['rolesUser/getCustomRole'](208)">
        <div class="d-flex justify-end align-center">
          <v-btn
            small
            color="primary"
            @click.prevent="createExpense"
            style="margin-right: 0.3rem"
          >
            <v-icon> mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
      <!-- bar -->

      <v-row>
        <v-col cols="12" class="mt-10">
          <h1>Administración gastos</h1>
        </v-col>
      </v-row>

      <!-- Table -->
     
        <ExpenseTable :newExpenses="expenses" @handleExpense="modExpense" v-if="$store.getters['rolesUser/getCustomRole'](221)"/>
      
      <!-- Table -->

      <!-- Dialog -->
      <v-dialog v-model="showForm" scrollable width="70%">
        <ExpenseDialog
          :win="win"
          :newAccountList="accountList"
          :newSelectedExpense="selectedExpense"
          :action="action"
          @handleAction="handleAction"
        />
      </v-dialog>
      <!-- Dialog -->
    </div>

    <!-- Alert -->
    <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
      class="deep-alert"
    >
    </alerts>
    <!-- Alert -->
  </div>
</template>

<script>
import Alerts from "@/components/Alerts";
import ExpenseDialog from "../ExpenseDialog.vue";
import ExpenseTable from "../ExpenseTable.vue";

export default {
  name: "ExpenseManagment",

  components: {
    Alerts,
    ExpenseDialog,
    ExpenseTable,
  },

  props: ["win"],

  data() {
    return {
      action: {
        ADD: false,
        EDIT: false,
        DEL: false,
      },
      selectedRowKeys: [],
      selectedExpense: {
        ExpenseName: null,
        ID: null,
        ID_AccountCatalog: null,
      },
      expenseName: null,
      expenses: [],
      accountList: {},
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      showForm: false,
      showDataForm: false,
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
    };
  },

  mounted() {
    this.getAllAcounts();
    this.getAllExpense();
  },

  methods: {
    acceptAlert() {
      this.alert.show = false;
    },
    closeAlert() {
      this.alert.show = false;
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    getAllExpense() {
      this.$API.accountingexpenses
        .getAllAccountingExpenses()
        .then(({ data }) => {
          this.expenses = data;
          console.log(this.expenses);
        })
        .catch((error) => console.log(error));
    },
    getAllAcounts() {
      try {
        this.$API.branchaccountcatalog
          .getActiveBranchAccountCatalog()
          .then(({ data }) => {
            this.accountList = data;
          });
      } catch (error) {
        console.log(error);
      }
    },
    onSelectionChanged(e) {
      if (e.selectedRowsData) {
        this.AcountItem = e.selectedRowsData[0].SelectedItem
          ? e.selectedRowsData[0]
          : null;
      }
      console.log(e.selectedRowsData[0]);
    },
    addExpenses(data) {
      if (data) {
        this.$API.accountingexpenses
          .saveAccountingExpenses(data)
          .then(() => {
            this.showAlert(
              "success",
              "Gasto agregado",
              `Se agregó el gasto correctamente`
            );
            this.getAllExpense();
          })
          .catch((error) => {
            this.showAlert("danger", "Error", `No se pudo relacionar el gasto: ${error}`);
          });
      } else {
        this.showAlert("danger", "Error", "Seleccione una cuenta válida");
      }
    },
    editExpenses(data) {
      if (data) {
        this.$API.accountingexpenses
          .updateAccountingExpenses(data)
          .then(() => {
            this.showDataForm = false;
            this.showAlert("success", "Gasto edito", `Se edito el gasto correctamente`);
            this.getAllExpense();
          })
          .catch((error) => {
            this.showAlert("danger", "Error", `No se pudo editar el gasto: ${error}`);
            this.showDataForm = false;
          });
      } else {
        this.showAlert("danger", "Error", "Seleccione una cuenta válida");
      }
    },
    deleteExpense({ ID }) {
      this.$API.accountingexpenses
        .deleteAccountingExpenses({ ID })
        .then(() => {
          this.showAlert(
            "success",
            "Gasto eliminado",
            `Se eliminó el gasto correctamente`
          );
          this.getAllExpense();
        })
        .catch((error) => {
          this.showAlert("danger", "Error", `No se pudo eliminar el gasto: ${error}`);
        });
    },
    handleAction({ action, ...data } = {}) {
      this.closeDialog();
      if (action === "add") {
        this.addExpenses(data);
      }
      if (action === "edit") {
        this.editExpenses(data);
      }
      if (action === "del") {
        this.deleteExpense(data);
      }
      if (action === "error") {
        this.errorExpense(data);
      }
    },
    createExpense() {
      this.showDialog();
      this.action.ADD = true;
    },
    modExpense(data) {
      this.showDialog();
      this.selectedExpense = data;
      this.action.EDIT = true;
      this.action.DEL = true;
    },
    closeDialog() {
      this.showForm = false;
      this.clean();
    },
    showDialog() {
      this.showForm = true;
      this.clean();
    },
    clean() {
      this.action = {
        ADD: false,
        EDIT: false,
        DEL: false,
      };
      this.selectedExpense = null;
    },
    errorExpense(data) {
      if (!data.ID_AccountCatalog) {
        this.showAlert(
          "danger",
          "Error",
          "No se seleciono una cuenta contable válida a la hora de agregar."
        );
      } else {
        this.showAlert("danger", "Error", "Vuelva a agregar los datos correctamente.");
      }
    },
  },
};
</script>

<style scoped>
.bar {
  width: 100%;
  background-color: rgb(60, 60, 60) !important;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-radius: 15px !important;
  margin-top: 1rem !important;
}

.box {
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid;
  border-color: black;
}

.expense-name {
  font-weight: bold;
  font-style: oblique;
}
</style>
