<template>
  <div>
    <div class="white box pa-6">
      <!-- Table -->
      
        <div>
          <GeneralFilter
            :filterEndpoint="$API.accountingexpensesentry.filteredAccountingExpensesEntry"
            :search="search"
            @emptyFilter="getAllEntries"
            @filtered="filterHandlerEntry"
            v-if="$store.getters['rolesUser/getCustomRole'](224)"
          >
            <DxDataGrid
              :data-source="entries"
              :show-row-lines="true"
              :show-borders="true"
              :row-alternation-enabled="true"
              :scrolling="{ useNative: true }"    
                    :column-auto-width="true"
            >
              
              <DxPaging :page-size="10" />
              <DxColumn :width="columnWidth" cell-template="showdata" v-if="$store.getters['rolesUser/getCustomRole'](219)"/>
              <DxColumn :width="columnWidth" caption="ID" data-field="ID"></DxColumn>
              <DxColumn :width="columnWidth" caption="ID proveedor" data-field="ID_Provider"></DxColumn>
              <DxColumn :width="columnWidth" caption="Nombre proveedor" data-field="ProviderName"></DxColumn>
              <DxColumn :width="columnWidth" caption="SubTotal" data-field="SubTotal"></DxColumn>
              <DxColumn :width="columnWidth" caption="Total" data-field="TotalExpense"></DxColumn>
              <template #showdata="{ data }">
                <a href="#" @click.prevent.stop="handleEntry(data.data)">
                  <v-icon color="primary">mdi-eye</v-icon>
                </a>
              </template>
            </DxDataGrid>
          </GeneralFilter>
        </div>
     
      <!-- Table -->

      <!-- Dialog -->
      <v-dialog v-model="showForm" width="80%">
        <v-card v-if="entry" class="white box pa-6">
          <v-row class="bg-dark text-h6 pa-3 rounded-lg mb-2">
            <v-col cols="12" md="6" align="center">
              <span class="font-weight-bold">ID Factura: </span>{{ entry.ID }}
            </v-col>
            <v-col cols="12" md="6" align="center">
              <span class="font-weight-bold">Proveedor: </span>{{ entry.ProviderName }} 
            </v-col>
          </v-row>
          <div class="box navbar" style="min-width: 250px; justify-content: space-around; flex-wrap: wrap;">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">SUB TOTAL</span>
              </div>
              <input type="text" class="form-control" :value="parseFloat(entry.SubTotal).toFixed(2)" disabled>
            </div>
            <div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">IVA</span>
                </div>
                <input type="text" class="form-control" :value="parseFloat(entry.Iva).toFixed(2)" disabled>
              </div>
            </div>
            <div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">EXENTAS</span>
                </div>
                <input type="text" class="form-control" :value="parseFloat(entry.Exentas).toFixed(2)" disabled>
              </div>
            </div>
            <div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">NO SUJ.</span>
                </div>
                <input type="text" class="form-control" :value="parseFloat(entry.NoSujetas).toFixed(2)" disabled>
              </div>
            </div>
            <div v-if="entry.ExpenseInvoiceType == 'FNS'">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="EXENTAS">Rete Renta</span>
                </div>
                <input type="text" class="form-control" :value="parseFloat(entry.ReteRenta).toFixed(2)" disabled>
              </div>
            </div>
            <div v-if="entry.ExpenseInvoiceType == 'FCF' || entry.ExpenseInvoiceType == 'CCF'">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="EXENTAS">IVA Ret.</span>
                </div>
                <input type="text" class="form-control" :value="parseFloat(entry.IvaRet).toFixed(2)" disabled>
              </div>
            </div>
            <div v-if="entry.ExpenseInvoiceType == 'FCF' || entry.ExpenseInvoiceType == 'CCF'">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" >IVA Per.</span>
                </div>
                <input type="text" class="form-control" :value="parseFloat(entry.IvaPerc).toFixed(2)" disabled>
              </div>
            </div>
            <div class="input-group ">
              <div class="input-group-prepend">
                <span class="input-group-text">VENTA TOTAL</span>
              </div>
              <input type="text" class="form-control" :value="parseFloat(entry.TotalExpense).toFixed(2)" disabled>
            </div>
          </div>
          <br/><br/>
          <v-row>
            <v-col cols="12" md="6">
              <h3 :class="showBgcolorNotes" style="text-align: center" @click="showComments = !showComments">
                Notas internas de la factura
              </h3>
            </v-col>
            <v-col cols="12" md="6">
              <h3 class="tabs" style="text-align: center" @click="showInfoInvoice = !showInfoInvoice">
                Datos de la factura del proveedor
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <!--GeneralFilter
              :filterEndpoint="
                $API.accountingexpensesentrydetail.filteredAccountingExpensesEntryDetails
              "
              :search="search"
              @emptyFilter="getAllEntriesDetails"
              @filtered="filterHandlerDetails"
            -->
              <DxDataGrid
                :data-source="entryDetails"
                :show-row-lines="true"
                :show-borders="true"
                :row-alternation-enabled="true"
                :scrolling="{ useNative: true }"    
                    :column-auto-width="true"
              >
                
                <DxPaging :page-size="10" />
                <DxColumn :width="columnWidth"  caption="ID Detalle" data-field="ID_Admin_Expenses"></DxColumn>
                <DxColumn :width="columnWidth"
                  
                  caption="Código de gasto"
                  data-field="ID_AccountingExpenses"
                ></DxColumn>
                <DxColumn :width="columnWidth" caption="Nombre" data-field="NameExpense"></DxColumn>
                <DxColumn :width="columnWidth" caption="Cantidad" data-field="Quantity"></DxColumn>
                <DxColumn :width="columnWidth" caption="Precio Unidad" data-field="UnitPrice"></DxColumn>
                <DxColumn :width="columnWidth" caption="Total" data-field="Total"></DxColumn>
                <DxColumn :width="100" caption="Exento" cell-template="IsExento"></DxColumn>
                <template #IsExento="{ data }">
                  <input type="checkbox" v-model="data.data.IsExento" disabled/>
                </template>
                <DxColumn :width="100" caption="No Sujeto" cell-template="IsNoSujeto"></DxColumn>
                <template #IsNoSujeto="{ data }">
                  <input type="checkbox" v-model="data.data.IsNoSujeto" disabled/>
                </template>
              </DxDataGrid>
            <!--/GeneralFilter-->
          </v-row>
        </v-card>
      </v-dialog>
      <!-- Dialog -->

      <v-dialog v-model="showInfoInvoice" width="60%">
        <v-card v-if="entry" class="border">
          <v-btn @click="showInfoInvoice = false" elevation="0" color="red" class="close-btn">
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-card-title class="text-h5">Datos de la factura</v-card-title>
          <v-card-text>
            <v-row class="mt-6 ml-2 mr-2 mb-6">
              <v-col cols="12" md="3">
                <center>
                  <v-img :src="entry.ExpenseInvoiceImage" width="100px" height="100px" class="preview-avatar"></v-img>
                </center>
                <br/>
                <center>
                  <a :href="entry.ExpenseInvoiceImage" style="vertical-align: bottom; border-radius: 5px;" class="btn btn-add pa-3" target="_blank" rel="noopener" v-if="entry.ExpenseInvoiceImage">Ver documento</a>
                  <p v-else>Compra sin documento de facturación.</p>
                </center>
                <br/>
              </v-col>

              <v-col cols="12" md="9">
                <v-row>
                  <v-col cols="12" md="6">
                    <date-picker label="Fecha de la Factura" v-model="entry.ExpenseInvoiceDate"
                    @dateSelected="(param) => (entry.ExpenseInvoiceDate = param)"></date-picker>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select outlined dense :items="catalogTypedocument" attach label="Tipo de Factura" v-model="entry.ExpenseInvoiceType" required
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-autocomplete outlined dense v-model="entry.ExpenseInvoiceDocumentType"
                      :items="documentOptions" :items-value="value" :items-text="text" attach
                      label="Tipo de documento" required></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined dense v-model="entry.ExpenseInvoiceId" required 
                      :label="entry.ExpenseInvoiceDocumentType == '2' ? 'Código de generación' : 'Correlativo de factura'"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-autocomplete outlined dense v-model="entry.ExpenseInvoicePayment"
                      :items="paymentMethod" attach label="Método de Pago" required></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete outlined dense v-model="entry.ExpenseInvoiceStatus" :items="invoiceStatus"
                      label="Estado de la Factura" attach required></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-autocomplete outlined dense v-if="entry.ExpenseInvoiceStatus == 'Crédito'"
                      v-model="entry.ExpenseInvoiceCreditDays" :items="days" label="Plazo" attach></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      
      <!-- Dialog -->
      <v-dialog v-model="showComments" width="60%">
        <v-card v-if="entry" class="border">
          <v-btn @click="showComments = false" elevation="0" color="red" class="close-btn">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-card-title class="text-h5">Notas internas de la factura</v-card-title>
          <v-card-text>
            <quill-editor
            :content="entry.ExpenseInvoiceComment"
            @change="onEditorChange($event)"
            ></quill-editor>
          </v-card-text>
          <v-card-actions>
            <v-col class="text-right">
              <v-btn @click="showComments = !showComments" class="btndisable">
                Continuar
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!-- Alert -->
    <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
      class="deep-alert"
    >
    </alerts>
    <!-- Alert -->
  </div>
</template>

<script>
import Alerts from "@/components/Alerts";
import { DxDataGrid, DxColumn,  DxPaging } from "devextreme-vue/data-grid";
import GeneralFilter from "@/components/GeneralFilter.vue";
import DatePicker from "../../../components/DatePicker";

export default {
  name: "ExpenseManagmentEntryHistory",

  components: {
    DxDataGrid,
    DxColumn,
    
    DxPaging,
    Alerts,
    GeneralFilter,
    DatePicker
  },

  props: ["win"],

  data() {
    return {
      entries: null,
      entry: null,
      entryDetails: null,
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      showForm: false,
      search: {
        filter: null,
      },
      entryID: null,
      showBgcolorNotes: "tabs",
      showComments: false,
      showInfoInvoice: false,
      catalogTypedocument: [],
      documentOptions: [
        { value: "1", text: "Físico" },
        { value: "2", text: "Electrónico" },
      ],
      paymentMethod: [
        "Efectivo",
        "Cheque",
        "Transferencia",
        "Tarjeta de Crédito",
        "Otro",
      ],
      invoiceStatus: ["Pendiente de pago", "Cancelada", "Crédito", "Anulada"],
      days: ['8 Dias', '15 Dias', '30 Dias', '90 Dias', '120 Dias', '180 Dias'],
    };
  },

  mounted() {
    this.getAllEntries();
    this.getCatalogTypedocument()
  },

  methods: {
    onEditorChange(e) {
      this.entry.ExpenseInvoiceComment = e.html;
      if (this.entry.ExpenseInvoiceComment != "") {
        this.showBgcolorNotes = "tabsContent";
      } else if (
        this.entry.ExpenseInvoiceComment == "" ||
        this.entry.ExpenseInvoiceComment == "<p><br></p>"
      ) {
        this.showBgcolorNotes = "tabs";
      } else {
        this.showBgcolorNotes = "tabs";
      }
    },

    getCatalogTypedocument() {
      this.$API.catalogtypedocument
        .getCatalogTypeDocumentForProvider()
        .then(({ data }) => {
          this.catalogTypedocument = data;
        });
    },
    acceptAlert() {
      this.alert.show = false;
    },
    closeAlert() {
      this.alert.show = false;
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    getAllEntries() {
      this.$API.accountingexpensesentry
        .findAllAccountingExpensesEntry()
        .then((data) => {
          this.entries = data;
        })
        .catch((error) => console.log("Error fetching entries:", error));
    },
    getAllEntriesDetails() {
      this.$API.accountingexpensesentrydetail
        .getAllAccountingExpensesEntryDetails(this.entryID)
        .then((data) => {
          this.entryDetails = data;
        })
        .catch((error) => console.log(error));
    },
    handleEntry(data) {
      console.log(data)
      this.showForm = true;
      this.entryID = data.ID;
      this.search.ID_AccountingExpensesEntry = data.ID
      this.getAllEntriesDetails();
      this.entry = data;

      if(this.entry.ExpenseInvoiceComment) {
        this.showBgcolorNotes = 'tabsContent'
      }
      else{
        this.showBgcolorNotes = 'tabs'
      }
    },
    filterHandlerEntry(data) {
      this.entries = data;
    },
    filterHandlerDetails(data) {
      this.entryDetails = data;
    },
  },
 computed: {
  columnWidth() {
    
    return this.$vuetify.breakpoint.smAndDown ? '150px' : 'auto !important';
  },
}}
</script>

<style scoped>
.box {
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid;
  border-color: black;
}

.expense-name {
  font-weight: bold;
  font-style: oblique;
}

.container-size {
  min-width: 100% !important;
}

.bg-dark {
  background-color: #41464c;
  color: white;
}

.btn-traspaso {
  background-color: #3498db !important;
  color: white;
}

.tabsContent {
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;

  border-radius: 10px;
}

.icon-color {
  color: white !important;
}

.red-text {
  color: #cc3333 !important;
}


.tabs {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.tabs:hover {
  background-color: rgba(80, 80, 80, 1);
  cursor: pointer;
}

.tabsContent:hover {
  background-color: #ffb266;
  cursor: pointer;
}

.tabsContent {
  width: 100%;
  padding: 10px;
  background-color: #f29d35;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.navbar {
    display: flex;
    background: #41464c;
    color: white;
    top: 0;
    align-self: flex-start;
    z-index: 100;
    position: sticky;
    justify-content: space-around;
}

.input-group.mb-3 {
    text-align: center;
}

.input-group-prepend {
    text-align: left;
}

input.form-control {
    background: #d6eaf3;
    border: 1px solid #1976d2;
    text-align: center;
    max-width: 80px;
    height: 30px;
    border-radius: 20px;
}

@media screen and (max-device-width: 1408px),
screen and (max-width: 1408px) {
    .navbar {
        margin-left: 0;
        margin-right: 0;
        height: auto;
        text-align: center;
    }

    .input-group-prepend {
        text-align: center;
    }
}

</style>
